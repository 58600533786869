<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Labels</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
                  >New Item</v-btn
                >
              </template>
              <v-card>
                <v-form
                  ref="form"
                  @submit.prevent="save()"
                  v-model="valid"
                  action="/"
                >
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.title"
                          label="Title"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :rules="[rules.required, rules.slug]"
                              v-model="editedItem.slug"
                              v-bind="attrs"
                              v-on="on"
                              label="SEO - Slug (hyphenated text used for creating url) (example: asco-2021-conference-clinical-trial-data)"
                          ></v-text-field>
                          </template>
                          <span>The slug is the part of the URL after the base:
                            <br>www.xyz.com/(slug here). Utilise 3-5 keywords from the page.
                          </span>
                        </v-tooltip>
                      </v-row>
                      <v-row>
                        <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.tab_title"
                            label="SEO - Page title (shown in browser tab)"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <span>SEO Page title - This is the title of the tab of the page and also shows up
                          <br>in search engines as the main title for the page.
                          <br>Keep the title short (max. 60 characters) and
                          <br>utilise keywords from the page.
                        </span>
                      </v-tooltip>
                      </v-row>
                      <v-row>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                          <v-textarea
                            v-model="editedItem.og_meta_desc"
                            v-bind="attrs"
                            v-on="on"
                            label="SEO - Meta description (this description will appear on google search results)"
                          ></v-textarea>
                          </template>
                          <span>The meta description is shown in search engines and
                            <br>is used to describe the contents of the page.
                            <br>Keep it between 50-160 characters and try to
                            <br>imagine what a user would search for in order
                            <br>to find the contents of the page.
                          </span>
                        </v-tooltip>
                      </v-row>
                      <v-row>
                        <v-textarea
                          :rules="[rules.required]"
                          v-model="editedItem.description"
                          label="Description"
                        ></v-textarea>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-eye</v-icon> -->
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { fetchLabels, addLabel, editLabel, deleteLabel } from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "Labels",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "title",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Label", value: "title" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      if (this.spinnerActive) return;
      this.items = [];
      setTimeout(async () => {
        let tableData = {};
        tableData["perPage"] = this.perPage;
        tableData["sortBy"] = this.sortBy;
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC";
        tableData["page"] = this.page;
        this.spinnerActive = true;
        // console.log('table Pagination ===> ', this.$refs.table)
        await fetchLabels(tableData)
          .then((res) => {
            if (res.data) {
              this.items = res.data.data.data;
              this.spinnerActive = false;
              this.total = res.data.data.total;
            }
          })
          .catch((error) => {
            console.log(error);
            this.spinnerActive = false;
          });
      }, 0);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await deleteLabel(this.editedItem)
        .then(() => {
          this.getItems();
          this.closeDelete();
        })
        .then((error) => {
          console.log("deleteLabel error => ", error);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let formValidated = this.$refs.form.validate();
      if (!formValidated) return;
      if (this.editedIndex > -1) {
        // EDIT
        await editLabel(this.editedItem)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addLabel error => ", error);
          });
      } else {
        // ADD
        await addLabel(this.editedItem)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addLabel error => ", error);
          });
      }
    },
  },
};
</script>
